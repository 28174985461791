<template>
  <div>
        <!-- 面包屑区域 -->
        <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

        <!-- 卡片视图区域 -->
        <el-card shadow="hover">
          <div class="top">
              <Title :title="title[0]"></Title>
              <el-button type="primary" @click="toComplaint">我要反馈</el-button>
          </div>

          <!-- 列表区域  border加边框 stripe隔行变色 -->
          <el-table :data="complaintInfo.info" border stripe :header-cell-style="{textAlign: 'center'}"  :cell-style="{textAlign: 'center'}" >
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column label="问题描述" prop="describe"></el-table-column>
            <el-table-column label="截图" prop="picture">
                <template v-slot:default="scope">
                      <el-image v-for="image in scope.row.picture" :key="image.id" :src="image.url" style="width: 100px; height: 100px;margin-right:5px" fit="fill"  :preview-src-list="PreviewImageList"  @click="getPreviewImageList(scope.row.picture)"></el-image>
               </template>
            </el-table-column>
            <el-table-column label="提交时间" prop="ctime" width="250px"></el-table-column>
            <el-table-column label="回复" prop="reply" width="300px"></el-table-column>
          </el-table>
    
          <!-- 分页区域 -->
          <el-pagination
            :current-page="queryInfo.pagenum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="complaintInfo.page_arr"
            :page-size="queryInfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="complaintInfo.num"
          ></el-pagination>
        </el-card>


        <!-- 意见反馈对话框 -->
        <el-dialog title="我要反馈" :visible.sync="complaintDialogVisible" width="50%" :close-on-press-escape="false" :close-on-click-modal="false" :destroy-on-close="true" :before-close="complaintDialogClosed">
          <!--内容主体区域-->
          <el-form :rules="complaintRules" ref="complaintFormRef" :model="complaintForm" status-icon  label-width="130px">
            <p class="textnum">{{number}}/1000</p>
            <el-form-item label="问题文字描述：" prop="textareaValue">
                  <el-input type="textarea" :rows="4" placeholder="请输入问题文字描述（必填）" v-model="complaintForm.textareaValue" maxlength="1000" v-on:input="inputChange($event)"></el-input>
            </el-form-item>
             <el-form-item label="截图：">
                  <el-upload action="/api/seller/feedbackimg" :headers="headersData" :data="uploadData" list-type="picture-card" :limit="bhAndLimitInfo.limit" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-error="imgError" :on-success="imgSuccess" :on-exceed="handleExceed"  :before-upload="beforeImageUpload">
                        <i class="el-icon-plus"></i>
                  </el-upload>
                  <p class="tips">（最多仅支持上传四张图片，且单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
                  <el-dialog :visible.sync="dialogVisible" :modal="false">
                     <img width="100%" :src="dialogImageUrl" alt="">
                 </el-dialog>
            </el-form-item>
          </el-form>
          <!--底部区域-->
          <span slot="footer" class="dialog-footer">
             <el-button type="primary" class="haowu hw-queren" @click="complaintSubmit"> 确定提交</el-button>
            <el-button  type="danger" class="haowu hw-quxiao" @click="complaintDialogClosed"> 取消退出</el-button>
          </span>
        </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getToken } from '@/utils/token'
import { allSpaces } from '@/utils/validate'
export default {
  data() {
    return {
           title: ["意见反馈"],
           //面包屑数据
           breadcrumbData: [
             {
               id: 1,
               title: "好物电商",
               path: "",
               isClick: false
             },
             {
               id: 2,
               title: "意见反馈",
               path: "complaint",
               isClick: true
             }
           ],
           //获取意见反馈列表的参数对象
           queryInfo: {
             // 当前的页数
             pagenum: 1,
             //当前每一页显示多少条数据
             pagesize: 10
           },
           //控制投诉对话框的显示与隐藏
           complaintDialogVisible:false,
           //反馈（投诉）对话框表单数据
           complaintForm:{
                //文本域的文字
                textareaValue:'',
           },
           //文本域文字的长度
           number:0,
           //预览图片列表
           PreviewImageList:[],
           //上传图片需要用到的其他参数
           uploadData:{},
           //上传图片需要用到的请求头参数
           headersData:{},
           //查看上传图片大图显示
           dialogImageUrl: '',
           //控制查看上传图片大图显示对话框的显示与隐藏
           dialogVisible: false,
           //修改复购时间表单的验证规则对象
           complaintRules:{
              textareaValue: [
                   { required: true, message: "文字描述不能为空", trigger: "blur" },
                   { validator: allSpaces, trigger: "blur" }
              ],
           },
    };
  },
  computed:{
       ...mapState({
           complaintInfo:state => state.complaint.complaintInfo,
           bhAndLimitInfo:state => state.complaint.bhAndLimitInfo
       }),

  },
  methods:{
        //获取意见反馈(投诉)数据
        getComplaintData(){
             this.$store.dispatch('getComplaintData',{num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})
        },

        //获取预览图片集
        getPreviewImageList(list){
             let imageList = []
             list.forEach(item => {
                  imageList.push(item.url)
             });
             this.PreviewImageList = imageList
        },

        //监听pagesize改变的事件
        handleSizeChange(newSize) {
          this.queryInfo.pagesize = newSize;
          this.queryInfo.pagenum = 1;
          this.getComplaintData();
        },
        
        //监听页码值改变的事件
        handleCurrentChange(newPage) {
          this.queryInfo.pagenum = newPage;
          this.getComplaintData();
        },

        //打开意见反馈对话框
        async toComplaint(){
            try{
                  await this.$store.dispatch('getBhAndLimit')
                  this.complaintDialogVisible = true;
                  this.uploadData.bh = this.bhAndLimitInfo.bh
                  //需替换为缓存中的token
                  // this.headersData.Authorization = 'qweqwe'
                  this.headersData.Authorization = getToken()
            }catch(error){
                  console.log(error.message)
            }
        },

        //监听文本输入框的文字
        inputChange(event){
             this.number = event.length
        },

       //图片上传成功
       imgSuccess(response, file, fileList) {
            if (response.code == 200) {
               this.$message.success(response.msg);
            } else {
              this.$message.error(response.msg);
            }
       },

       //限制用户上传的图片格式和大小。
       beforeImageUpload(file) {
              const isJPG = file.type === 'image/jpeg'|| file.type == 'image/png' || file.type == 'image/jpg';
              const isLt2M = file.size / 1024 / 1024 < 2;

              if (!isJPG) {
                  this.$message.error('上传图片只能是JPG/PNG/JPEG 格式!');
              }
              if (!isLt2M) {
                  this.$message.error('上传图片大小不能超过2MB!');
              }
              return isJPG && isLt2M;
       },
       
       //上传图片数量超出限制
       handleExceed(files, fileList) {
            this.$message.error("上传数量超过限制");
       },

       //图片上传失败
       imgError(err, file, fileList) {
           this.$message.error(err);
       },

       //删除图片
       async handleRemove(file, fileList) {
           try{
                const { id } = file.response.data
                await this.$store.dispatch('deleteComplaintImage',{bh:this.bhAndLimitInfo.bh,id})
                this.$message.success('删除成功');
           }catch(error){
                console.log(error.message)
           }
       },

       //点击图片列表中已上传的图片时的触发
       handlePictureCardPreview(file) {
           this.dialogImageUrl = file.url;
           this.dialogVisible = true;
       },

       //提交反馈（投诉）
       complaintSubmit(){
             this.$refs.complaintFormRef.validate(async valid => { 
                 if(!valid) return
                 try{
                      const { textareaValue } = this.complaintForm
                      await this.$store.dispatch('complaintSubmit',{describe:textareaValue,bh:this.bhAndLimitInfo.bh})
                      this.complaintDialogVisible = false;
                      this.$message.success('您的反馈已提交！')
                      this.complaintForm.textareaValue = ''
                      this.uploadData = {}
                      this.headersData = {}
                      this.getComplaintData()
                 }catch(error){
                      this.$message.error('提交失败，请稍后重试！')
                 }
             })
       },

       //监听提交反馈（投诉）对话框的关闭事件
       complaintDialogClosed(){
             // 打开提示弹窗
             this.$confirm('是否取消反馈？', '提示', {
                  distinguishCancelAndClose: true,
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                  center: true
             })
             .then(async () => {
                   try{
                         await this.$store.dispatch('complaintNotSubmit',{bh:this.bhAndLimitInfo.bh})
                         this.complaintDialogVisible = false
                         this.complaintForm.textareaValue = ''
                         this.uploadData = {}
                         this.headersData = {}
                         this.$refs.complaintFormRef.resetFields();
                   }catch(error){
                         console.log(error.message)
                   }
             })
             .catch(action => {
  
             });
       },  

       
  },
  created(){
      //获取意见反馈(投诉)数据
      this.getComplaintData()
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin: 20px 20px 0 20px;
}
.el-table {
    margin-top: 20px;
}
.el-pagination {
  margin-top: 20px;
}
.top{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.textnum{
   text-align: right;
   margin-top:0 !important;
   margin-bottom:5px;
   padding-right:5px;
   font-weight: 900;
}
.dialog-footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tips {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #a2a2a2;
    margin:0 !important;
}
.el-card {
    margin: 20px 20px 50px 20px;
}
</style>